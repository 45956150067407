@use "./globals" as *;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$playground-pwa-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$playground-pwa-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$playground-pwa-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$playground-pwa-theme: mat.m2-define-light-theme((
  color: (
    primary: $playground-pwa-primary,
    accent: $playground-pwa-accent,
    warn: $playground-pwa-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($playground-pwa-theme);

/* You can add global styles to this file, and also import other style files */

// root variables
:root {
  --text-color: #fff;
  --theme-color-1: #638;
  --theme-color-2: #A33276;
  --theme-color-3: #FE8C46;
  --theme-color-5: #A33377;
  --theme-color-6: #F9874A;
  --theme-color-7: #FF45A4;
  --border-gradient-width: 2px;
  --gradient-2: linear-gradient(72deg, var(--theme-color-1) 9.73%, var(--theme-color-2) 67.83%, var(--theme-color-3) 113.87%);
  --gradient-dark: radial-gradient(50% 50% at 50% 50%, #221D50 0%, #202034 100%);
  --gradient-dark2: linear-gradient(8deg, rgba(45, 18, 214, 0.20) 2.3%, rgba(16, 0, 113, 0.00) 96.15%), #1F1F33;
  // --gradient-conic: conic-gradient(from -8deg at 50% 50%, var(--theme-color-5) 51.49893879890442deg, var(--theme-color-6) 111.40947818756104deg, var(--theme-color-5) 206.36622190475464deg, var(--theme-color-3) 268.8051509857178deg, var(--theme-color-2) 306.07335090637207deg);
  --gradient-conic: linear-gradient(282deg, var(--theme-color-7) 3.15%, var(--theme-color-3) 126.79%);
  --btn-a-text-color: #fff;
  --btn-b-text-color: #000;
  --btn-b-bg-color: #fff;
  --btn-c-bg-color: transparent;
  --btn-c-text-color: #fff;
  --btn-c-disabled-text-color: #A5A5AD;
  --btn-d-text-color: transparent;
  --btn-d-bg-color-active: rgba(255, 255, 255, 0.15);
  --btn-e-bg-color-active: #fff;
  --btn-e-text-color-active: #000;
  --modal-bg: #1F1F33dd;
  --btn-font-size: 14px;
  --btn-sm-font-size: 12px;
  --btn-large-height: 44px;
  --h1-font-size: 48px;
  --h2-font-size: 40px;
  --h3-font-size: 32px;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 20px;
  --h7-font-size: 16px;
  --h1-line-height: 56px;
  --h2-line-height: 48px;
  --h3-line-height: 40px;
  --h4-line-height: 36px;
  --h5-line-height: 32px;
  --h6-line-height: 26px;
  --h7-line-height: 24px;
  --xl-txt-font-size: 20px;
  --md-txt-font-size: 16px;
  --sm-txt-font-size: 14px;
  --xs-txt-font-size: 14px;
  --xxs-txt-font-size: 12px;
  --xxxs-txt-font-size: 11px;
  --xl-line-height: 28px;
  --md-line-height: 24px;
  --sm-line-height: 20px;
  --xs-line-height: 16px;
  --xxs-line-height: 14px;
  --xxxs-line-height: 10px;
  --btn-font-weight: 600;
  --font-weight-semi-bold: 500;
  --font-weight-bold: 600;
  --font-weight-bolder: 700;
  --font-weight-nornal: 400;
  --btn-big-vertical-padding: 15px;
  --duration: .3s;
  --duration-slow: .5s;
  --font-family: 'Montserrat', sans-serif;
  --font-family-b: "Times New Roman";
  --body-bg: #191929;
  --bg-a: #1F1F33;
  --padd-vertical: 12px;
  --padd-horizontal: 16px;
  --small-margin: 8px;
  --xs-margin: 4px;
  --link-text-color: #B2C8FF;
  --input-transparent-bg-color: transparent;
  --input-transparent-bg-border: #fff;
  --header-height: 60px;
  --scrollbar-width: 6px;
  --border-radius: 6px;

  --gray-10: #F4F4F5;
  --gray-20: #E9E9EB;
  --gray-30: #D2D2D6;
  --gray-40: #BCBCC2;
  --gray-50: #A5A5AD;
  --gray-60: #626270;
  --gray-70: #353547;
  --gray-80: #2A2A3D;
  --gray-90: #191929;
  --gray-100: #191929;

  --red-10: #FEE1E1;
  --red-20: #FCA6A6;
  --red-30: #EF4343;
  --red-40: #BA2532;

  --green-10: #D1FAE9;
  --green-20: #6EE7B5;
  --green-30: #08A464;
  --green-40: #088752;

  --blue-10: #DFE8FF;
  --blue-20: #B2C8FF;
  --blue-30: #2E5AE0;

  --orange-10: #FFF5D6;
  --orange-20: #DD7208;

  --pink-30: #FF45A4;

  --spacing-xxs: 2px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
  --spacing-2xl: 40px;
  --spacing-3xl: 72px;

  --radius-xs: 2px;
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 10px;
  --radius-xl: 360px;

  --filter-disabled: 0.5;

  --advanced-btn-width: 40px;

  --mobile-threshold: $mobile-threshold;

  --left-sidebat-width: 348px;

  --progress-bar-height: 15px;

  --mobile-menu-gap: 54px;

  --h1-max-width: 430px;
  --h1-img-height: 40px;
  --line-height-1-5: 1.5;

  @include mat.select-overrides((
    enabled-trigger-text-color: var(--text-color),
    disabled-trigger-text-color: var(--text-color),
    placeholder-text-color: var(--btn-c-disabled-text-color),
    enabled-arrow-color: var(--text-color),
    disabled-arrow-color: var(--text-color),
    focused-arrow-color: var(--text-color),
  ));

  @include mat.menu-overrides((
    container-color: var(--bg-a),
  ));

  @include mat.expansion-overrides((
    container-background-color: transparent,
    container-text-color: var(--gray-10),
    header-text-color: var(--gray-10),
    header-indicator-color: var(--gray-10),
  ));

  @include mat.slide-toggle-overrides((
    track-outline-color: var(--gray-60),
    unselected-track-color: var(--gray-60),
    disabled-unselected-track-color: var(--gray-60),
    selected-focus-track-color: var(--gray-60),
    selected-hover-track-color: var(--gray-60),
    selected-pressed-track-color: var(--gray-60),
    selected-focus-handle-color: var(--gray-60),
    unselected-focus-handle-color: var(--gray-60),
    unselected-focus-track-color: var(--gray-60),
    unselected-hover-track-color: var(--gray-60),
    handle-height: 20px,
    track-height: 20px,
    // label-text-color: var(--text-color),
  ));

  @include mat.bottom-sheet-overrides((
    container-text-color: var(--gray-50),
    container-background-color: var(--bg-a),
  ));

  
  body {
    --mdc-switch-track-width: 32px;
    --mdc-switch-track-shape: 12px;
    --mdc-switch-selected-icon-color: var(--gray-10);
    --mdc-switch-selected-handle-color: var(--gray-10);
    --mdc-switch-disabled-track-opacity: 1;
  }

  .mat-mdc-slide-toggle {
    .mat-internal-form-field {
      --mat-switch-label-text-color: var(--text-color);
    }
  }

  .mdc-switch__handle::before {
    --mdc-switch-handle-surface-color: var(--gray-10);
  }
  .mdc-switch--selected .mdc-switch__handle-track, .mdc-switch__handle-track {
    scale: 0.8;
    left: 1px;
  }

  .mdc-switch__icons {
    background: var(--gray-10);
    border-radius: var(--mdc-switch-track-shape);
  }

  .mdc-switch--selected .mdc-switch__icon--on, .mdc-switch--unselected .mdc-switch__icon--off {
    opacity: 0;
  }
  

  // override material variables
  body {
    --mdc-protected-button-label-text-font: var(--font-family);
    --mdc-protected-button-label-text-size: var(--btn-font-size);
    --mdc-filled-text-field-focus-active-indicator-color: var(--theme-color-2);
    background: var(--body-bg);
    color: var(--text-color);
    mat-slide-toggle[labelPosition="before"] {
      width: 100%;
      .mdc-form-field--align-end>label {
        margin-left: 0;
      }
      .mdc-form-field.mat-internal-form-field.mdc-form-field--align-end {
        width: 100%;
        .mdc-switch {
          margin-left: auto;
        }
      }
    }
    .mat-expansion-panel:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }
    .mat-expansion-panel-header {
      padding: 0 1px 0 0;
    }
    .mat-expansion-panel-body {
      padding-left: 0;
      padding-right: 0;
    }
    header {
      box-shadow: 0px -1px 0px var(--gray-70) inset;
    }
  }
  body.gradient {
    background: var(--gradient-dark);
    header {
      box-shadow: unset;
    }
  }
  body.gradient2 {
    background: var(--gradient-dark2);
    header {
      box-shadow: unset;
    }
  }
}

// HTML BODY
// @media (orientation: landscape) {
//   body {
//       font-size: 2vh;
//   }
// }
// @media (orientation: portrait) {
//   body {
//       font-size: 4vw;
//   }
// }

html body {
  font-family: var(--font-family);
}

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

*:not(html):not(body)::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 var(--scrollbar-width) rgba(0, 0, 0, 0.3);
  background-color: var(--gray-80);
}

*:not(html):not(body)::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-width);
  background-color: var(--gray-80);
}

*:not(html):not(body)::-webkit-scrollbar-thumb {
  background-color: var(--gray-10);
}

// *:not(html):not(body)::-webkit-scrollbar-thumb {
//   background-color: transparent;
//   transition-duration: var(--duration);
//   transition-property: background-color;
// }

// *:not(html):not(body):hover::-webkit-scrollbar-thumb {
//   background-color: var(--gray-10);
// }

.view-height {
  height: calc(100svh - var(--header-height));
}

.view-min-height, router-outlet + * {
  min-height: calc(100svh - var(--header-height));
}

.view-max-width {
  max-width: 1296px;
}


.view-min-height-mp {
  min-height: calc(100svh - var(--header-height) - var(--padd-vertical) - var(--padd-vertical));
}

router-outlet + * {
  display: block;
}

@media (max-width: $mobile-threshold) {
  .hidden-mobile, .hidden-mobile.disp-flex {
    display: none;
  }
  .center-me-mobile {
    margin: auto;
  }
  .mobile-col, .mobile-col.row, .mat-button-toggle-standalone.mobile-col, .mat-button-toggle-group.mobile-col {
    flex-direction: column;
  }
  .mobile-col-reverse, .mobile-col-reverse.row, .mat-button-toggle-standalone.mobile-col-reverse, .mat-button-toggle-group.mobile-col-reverse {
    flex-direction: column-reverse;
  }
  .buttons.mobile-col {
    .buttons-group {
      flex-direction: column;
      align-items: center;
    }
    .button-wrap:not(:first-child) {
      margin-left: 0;
    }
    .button-wrap {
      width: 48px;
      button.mdc-button {
        min-width: 48px;
      }
    }
  }
  .buttons.mobile-small {
    .button-wrap {
      width: 48px;
      button.mdc-button {
        min-width: 48px;
      }
    }
    .buttons-group {
      .button-wrap {
        width: 48px;
        button.mdc-button {
          width: 48px;
        }
      }
    }
  }
  .mobile-row, .mobile-row.col {
    flex-direction: row;
  }
  .mobile-row-reverse, .mobile-row-reverse.col {
    flex-direction: row-reverse;
  }
  body {
    .btn.large-desktop, .mat-mdc-raised-button.btn.large-desktop, .btn.mdc-button--raised.large-desktop {
      --mdc-protected-button-label-text-size: var(--btn-font-size);
      --mdc-protected-button-container-height: 36px;
    }
    .btn.no-marg-img-mobile, .btn.disabled.no-marg-img-mobile, .mat-mdc-raised-button.btn.no-marg-img-mobile, .mat-mdc-raised-button:not(:disabled).no-marg-img.btn, .btn.mdc-button--raised.no-marg-img-mobile {
      img {
        margin-right: 0;
      }
    }
    body.no-padd-dialog {
      .mat-mdc-dialog-container-with-actions {
        .mat-mdc-dialog-content {
          overflow: auto;
        }
      }
    }
    .space-bottom-xs-mobile {
      margin-bottom: var(--spacing-xs) !important;
    }
    .space-bottom-sm-mobile {
      margin-bottom: var(--spacing-sm) !important;
    }
  
    .space-bottom-md-mobile {
      margin-bottom: var(--spacing-md) !important;
    }
  
    .space-bottom-lg-mobile {
      margin-bottom: var(--spacing-lg) !important;
    }
  
    .space-bottom-xl-mobile {
      margin-bottom: var(--spacing-xl) !important;
    }
  
    .space-bottom-2xl-mobile {
      margin-bottom: var(--spacing-2xl) !important;
    }
    .space-bottom-3xl-mobile {
      margin-bottom: var(--spacing-3xl) !important;
    }
  }
}

@media (min-width: 1024px) {
  .hidden-desktop, .hidden-desktop.disp-flex, .hidden-desktop.disp-block, .hidden-desktop.mdc-button {
    display: none;
  }
  .vertical-align-desktiop-only {
    transform: translateY(-50%);
    top: 50%;
    position: relative;
  }
}

// global classes

.blur {
  filter: blur(5px);
}

.rotate {
  transform: rotateY(180deg);
}

.sub-label {
  font-size: 11px;
  color: var(--green-40);
  padding: calc(var(--spacing-xs) + 1px) var(--spacing-sm);
  border-radius: var(--radius-xl);
  border: 1px solid var(--green-20);
  background: var(--green-10);
  font-weight: var(--font-weight-bold);
  line-height: var(--md-line-height);
}

.invert {
  filter: invert(1);
}

.filter-disabled {
  opacity: var(--filter-disabled);
}

.poiner {
  cursor: pointer;
}

.italic {
  font-style: italic;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-fl::first-letter {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.center-me {
  margin: auto;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-start, .btn.text-start {
  text-align: start;
  .mdc-button__label {
    width: 100%;
  }
}

.text-end {
  text-align: end;
}

.align-end-flex {
  margin-left: auto;
}

.float-dir {
  float: left;
}

.float-op-dir {
  float: right;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.pc-space-between {
  place-content: space-between;
}

.pc-center-space-between {
  place-content: center space-between;
}

.pc-center-center {
  place-content: center center;
}

.pc-space-around-center {
  place-content: space-around center;
}

.pc-space-between-center {
  place-content: space-between center;
}

.align-items-center {
  align-items: center;
}

.align-content-center {
  align-content: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-def {
  flex: 1 1 1e-09px;
}

.flex-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.disp-flex, .disp-flex.center-me {
  display: flex;
}

.disp-inline-flex {
  display: inline-flex;
}

.disp-inline-block {
  display: inline-block;
}

.disp-grid {
  display: grid;
}

.disp-block {
  display: block;
}

.disp-none, .disp-none.mat-mdc-progress-bar {
  display: none;
}

.hidden {
  visibility: hidden;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.btn-icon {
  vertical-align: middle;
}

.icon-sm {
  width: 16px;
}

.space-top, .ttl-sm.space-top {
  margin-top: var(--padd-horizontal);
}

body {
  .space-bottom-xs {
    margin-bottom: var(--spacing-xs);
  }
  .space-bottom-sm {
    margin-bottom: var(--spacing-sm);
  }

  .space-bottom-md {
    margin-bottom: var(--spacing-md);
  }

  .space-bottom-lg {
    margin-bottom: var(--spacing-lg);
  }

  .space-bottom-xl {
    margin-bottom: var(--spacing-xl);
  }

  .space-bottom-2xl {
    margin-bottom: var(--spacing-2xl);
  }
  .space-bottom-3xl {
    margin-bottom: var(--spacing-3xl);
  }
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.max-w-100 {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.vertical-align {
  transform: translateY(-50%);
  top: 50%;
  position: relative;
}

.vertical-align.pos-abs {
  position: absolute;
}

.horizontal-align {
  transform: translateX(-50%);
  left: 50%;
  position: relative;
}

.horizontal-align.pos-abs {
  position: absolute;
}

.content-va {
  content-visibility: auto;
}

.va-middle {
  vertical-align: middle;
}

.va-super {
  vertical-align: super;
}

.va-bottom {
  vertical-align: bottom;
}

.va-text-top {
  vertical-align: text-top;
}

.va-top {
  vertical-align: top;
}

.va-sub {
  vertical-align: sub;
}

.pointer {
  cursor: pointer;
}

.pos-rel {
  position: relative;
}

.pos-abs, .mdc-icon-button.pos-abs, mat-progress-bar.pos-abs, .pos-abs.mdc-button, .mat-mdc-fab-base.pos-abs {
  position: absolute;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.no-marg {
  margin: 0;
}

.no-padd, .mdc-button.no-padd, .mat-mdc-raised-button.no-padd {
  --mat-protected-button-horizontal-padding: 0;
  padding: 0;
}

.no-min-width {
  min-width: unset;
}

.text-gradient {
  background: var(--gradient-conic);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sharpen1 {
  filter: url(#Sharpen1);
}
.sharpen2 {
  filter: url(#Sharpen2);
}
.sharpen3 {
  filter: url(#Sharpen3);
}

.center-me,
.ttl-xs.center-me,
.mdc-button.center-me,
.mdc-fab.center-me,
.mdc-icon-button.center-me,
.mat-mdc-mini-fab.center-me,
.mat-raised-button.center-me,
.mat-mdc-select.center-me {
    display: block;
    margin: auto;
}

.advanced-button {
  position: relative;
  app-advanced-options {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 0;
    min-width: 316px;
    .advanced-btn {
      // height: 100%;
      right: 0;
      position: absolute;
      height: 33px;
      bottom: 9px;
      box-shadow: none;
    }
    .advanced-btn.mat-mdc-button .mat-mdc-button-touch-target {
      height: 32px;
    }
    .menu {
      bottom: 45px;
    }
  }
  > button.btn {
    text-indent: calc(-1 * var(--advanced-btn-width));
  }
  .btn.large + app-advanced-options, .btn.ab-l + app-advanced-options {
    .advanced-btn {
      bottom: 1px;
    }
  }
  .btn.large + app-advanced-options {
    .advanced-btn {
      height: 40px;
    }
  }
}

// @media (max-width: $mobile-threshold) {
//   .advanced-button {
//     app-advanced-options {
//       .menu {
//         bottom: 0;
//       }
//     }
//   }
// }

// override components

body {
  --mdc-plain-tooltip-supporting-text-color: var(--gray-100);
  --mdc-plain-tooltip-container-shape: var(--radius-xs);
  --mdc-plain-tooltip-container-color: var(--gray-10);
  --mdc-plain-tooltip-supporting-text-size: var(--xxs-txt-font-size);
  --mdc-plain-tooltip-supporting-text-weight: var(--font-weight-bold);
  .mat-mdc-tooltip {
    .mdc-tooltip__surface {
      border: 1px solid var(--gray-30);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .mat-divider {
    --mat-divider-color: var(--gray-70);
  }
  .mat-divider.gray-60 {
    --mat-divider-color: var(--gray-60);
  }
  .mat-divider.gray-50 {
    --mat-divider-color: var(--gray-50);
  }
  .mat-mdc-checkbox {
    --mat-sys-on-surface-variant: var(--text-color);
    --mat-checkbox-label-text-color: var(--text-color);
    .mdc-checkbox__background {
      color: var(--text-color);
      --mdc-checkbox-unselected-icon-color: var(--text-color);
      --mdc-checkbox-unselected-focus-icon-color: var(--text-color);
    }
  }
  .mdc-checkbox:hover .mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background, .mdc-checkbox:hover .mdc-checkbox__native-control:not(:indeterminate)~.mdc-checkbox__background {
    --mdc-checkbox-unselected-hover-icon-color: var(--text-color);
  }
  
}

body.transparent-dialog {
  --mdc-dialog-container-color: transparent;
  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none;
  }
}

body.no-padd-dialog {
  .mat-mdc-dialog-container-with-actions {
    .mat-mdc-dialog-content {
      padding: 0;
      overflow: hidden;
    }
  }
}

body.pricing-dialog {
  .mat-mdc-dialog-container {
    overflow: hidden;
    border-radius: var(--spacing-lg);
    max-height: 100svh;
  }
}

body {
  .mat-mdc-dialog-surface {
    background: var(--body-bg);
  }
}


.mat-mdc-raised-button.btn.no-height {
  --mdc-protected-button-container-height: initial;
}
a.btn {
  color: inherit;
  text-decoration: inherit;
}
.btn::first-letter {
  text-transform: capitalize;
}
.btn:not(mat-button-toggle), .btn.disabled, .mat-mdc-raised-button.btn, .mat-mdc-raised-button:not(:disabled).btn, .btn.mdc-button--raised {
  border-radius: var(--radius-sm);
  transition-duration: var(--duration);
  transition-property: all;
  --mdc-protected-button-label-text-weight: var(--btn-font-weight);
  --mdc-protected-button-hover-container-elevation-shadow: none;
  .mdc-button__label::first-letter {
    text-transform: capitalize;
  }
  img {
    margin-right: var(--spacing-xs);
  }
}
.btn.no-marg-img, .btn.disabled.no-marg-img, .mat-mdc-raised-button.btn.no-marg-img, .mat-mdc-raised-button:not(:disabled).no-marg-img.btn, .btn.mdc-button--raised.no-marg-img {
  --mat-protected-button-icon-spacing: 0;
  img {
    margin-right: 0;
  }
}
.btn.btn-img, .btn.disabled.btn-img, .mat-mdc-raised-button.btn.btn-img, .mat-mdc-raised-button:not(:disabled).btn.btn-img, .btn.mdc-button--raised.btn-img, .mdc-button.btn.btn-img {
  --mat-protected-button-horizontal-padding: 8px;
  min-width: unset;
  height: 34px;
  img {
    margin-right: 0;
  }
}
// .btn.active {
//   img.invert {
//     filter: invert(0);
//   }
// }
.btn-a, .mat-mdc-raised-button.btn-a, .mat-mdc-raised-button:not(:disabled).btn-a, .btn-a.mdc-button--raised {
  background: var(--gradient-2);
  --mdc-protected-button-label-text-color: var(--btn-a-text-color);
}
.btn-a:disabled, .btn-a.disabled, .mat-mdc-raised-button.btn-a:disabled {
  background: var(--gray-60);
  --mdc-protected-button-disabled-label-text-color: var(--gray-50);
}
.btn-b, .mat-mdc-raised-button.btn-b, .mat-mdc-raised-button:not(:disabled).btn-b, .btn-b.mdc-button--raised {
  --mdc-protected-button-container-color: var(--btn-b-bg-color);
  --mdc-protected-button-label-text-color: var(--btn-b-text-color);
}
.btn-b:disabled, .btn-b.disabled, .mat-mdc-raised-button.btn-b:disabled {
  background: var(--gray-60);
  --mdc-protected-button-disabled-label-text-color: var(--gray-50);
}
.btn-c, .mat-mdc-raised-button.btn-c, .mat-mdc-raised-button:not(:disabled).btn-c, .btn-c.mdc-button--raised {
  --mdc-protected-button-container-color: var(--btn-c-bg-color);
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px var(--btn-c-text-color);
  box-shadow: none;
}
.btn-c.no-border, .mat-mdc-raised-button.btn-c.no-border, .mat-mdc-raised-button:not(:disabled).btn-c.no-border, .btn-c.no-border.mdc-button--raised {
  border: none;
}
.btn-c.active, .mat-mdc-raised-button.btn-c.active, .mat-mdc-raised-button:not(:disabled).btn-c.active, .btn-c.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--gray-10);
  --mdc-protected-button-disabled-container-color: var(--gray-10);
  --mdc-protected-button-disabled-label-text-color: var(--bg-a);
  // --mdc-protected-button-disabled-label-text-color: var(--btn-c-text-color);
}
.btn-c:not(.active):disabled, .btn-c.disabled:not(.active), .mat-mdc-raised-button.btn-c:not(.active):disabled {
  --mdc-protected-button-disabled-label-text-color: var(--btn-c-disabled-text-color);
}
.btn-c:not(.active):hover, .btn-c:not(.active):hover, .mat-mdc-raised-button.btn-c:not(.active):hover {
  --mdc-protected-button-container-color: var(--btn-d-bg-color-active);
}
.btn-d, .mat-mdc-raised-button.btn-d, .mat-mdc-raised-button:not(:disabled).btn-d, .btn-d.mdc-button--raised {
  --mdc-protected-button-container-color: var(--btn-c-bg-color);
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px var(--btn-d-text-color);
  box-shadow: none;
}
.btn-d.active, .mat-mdc-raised-button.btn-d.active, .mat-mdc-raised-button:not(:disabled).btn-d.active, .btn-d.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--btn-d-bg-color-active);
}
.btn-e, .mat-mdc-raised-button.btn-e, .mat-mdc-raised-button:not(:disabled).btn-e, .btn-e.mdc-button--raised {
  --mdc-protected-button-container-color: var(--btn-c-bg-color);
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px var(--btn-d-text-color);
}
.btn-e.active, .mat-mdc-raised-button.btn-e.active, .mat-mdc-raised-button:not(:disabled).btn-e.active, .btn-e.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--btn-e-bg-color-active);
  --mdc-protected-button-label-text-color: var(--btn-e-text-color-active);
}

.btn-f, .mat-mdc-raised-button.btn-f, .mat-mdc-raised-button:not(:disabled).btn-f, .btn-f.mdc-button--raised {
  --mdc-protected-button-container-color: var(--btn-c-bg-color);
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px var(--gray-70);
}
.btn-f.active, .mat-mdc-raised-button.btn-f.active, .mat-mdc-raised-button:not(:disabled).btn-f.active, .btn-f.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--btn-e-bg-color-active);
  --mdc-protected-button-label-text-color: var(--btn-e-text-color-active);
}

.btn-g, .mat-mdc-raised-button.btn-g, .mat-mdc-raised-button:not(:disabled).btn-g, .btn-g.mdc-button--raised {
  --mdc-protected-button-container-color: var(--gray-70);
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  --mdc-protected-button-container-elevation-shadow: none;
}
.btn-g.active, .mat-mdc-raised-button.btn-g.active, .mat-mdc-raised-button:not(:disabled).btn-g.active, .btn-g.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--gray-10);
  --mdc-protected-button-label-text-color: var(--bg-a);
}
.btn-g:not(.active):hover, .mat-mdc-raised-button.btn-g:not(.active):hover, .mat-mdc-raised-button:not(.active):not(:disabled).btn-g:hover, .btn-g.mdc-button--raised:not(.active):hover {
  --mdc-protected-button-container-color: var(--btn-d-bg-color-active);
}

// .btn-h, .mat-mdc-raised-button.btn-h, .mat-mdc-raised-button:not(:disabled).btn-h, .btn-h.mdc-button--raised {
//   --mdc-protected-button-container-color: var(--gray-90);
//   --mdc-protected-button-label-text-color: var(--gray-10);
//   --mdc-protected-button-container-elevation-shadow: none;
// }
// .btn-h.active, .mat-mdc-raised-button.btn-h.active, .mat-mdc-raised-button:not(:disabled).btn-h.active, .btn-h.mdc-button--raised.active {
//   --mdc-protected-button-container-color: var(--gray-10);
//   --mdc-protected-button-label-text-color: var(--bg-a);
// }
// .btn-h:not(.active):hover, .mat-mdc-raised-button.btn-h:not(.active):hover, .mat-mdc-raised-button:not(.active):not(:disabled).btn-h:hover, .btn-h.mdc-button--raised:not(.active):hover {
//   --mdc-protected-button-container-color: var(--btn-d-bg-color-active);
// }

.btn-ghost, .mat-mdc-raised-button.btn-ghost, .mat-mdc-raised-button:not(:disabled).btn-ghost, .btn-ghost.mdc-button--raised, .btn-ghost.mat-mdc-mini-fab.mat-accent {
  --mdc-protected-button-container-color: transparent;
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px transparent;
  box-shadow: none;
  --mat-fab-small-foreground-color: transparent;
  --mat-fab-small-state-layer-color: transparent;
  // --mat-fab-small-ripple-color: ;
  --mdc-fab-small-container-color: transparent;
}
.btn-ghost:hover, .mat-mdc-raised-button.btn-ghost:hover, .mat-mdc-raised-button:not(:disabled).btn-ghost:hover, .btn-ghost.mdc-button--raised:hover {
  // --mdc-protected-button-container-color: var(--gray-70);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.15);
}
.btn-ghost.active, .mat-mdc-raised-button.btn-ghost.active, .mat-mdc-raised-button:not(:disabled).btn-ghost.active, .btn-ghost.mdc-button--raised.active {
  --mdc-protected-button-container-color: var(--gray-10);
  --mdc-protected-button-label-text-color: var(--bg-a);
}

.btn-ghost.active2, .mat-mdc-raised-button.btn-ghost.active2, .mat-mdc-raised-button:not(:disabled).btn-ghost.active2, .btn-ghost.mdc-button--raised.active2 {
  --mdc-protected-button-container-color: transparent;
  --mdc-protected-button-label-text-color: var(--btn-c-text-color);
  border: solid 1px var(--btn-c-text-color);
}

.btn.big, .mat-mdc-raised-button.btn.big, .btn.mdc-button--raised.big {
  padding: var(--btn-big-vertical-padding) var(--mat-protected-button-horizontal-padding);
  --mdc-protected-button-container-height: initial;
}

.btn.large, .mat-mdc-raised-button.btn.large, .btn.mdc-button--raised.large,
.btn.large-desktop, .mat-mdc-raised-button.btn.large-desktop, .btn.mdc-button--raised.large-desktop {
  --mdc-protected-button-label-text-size: var(--md-txt-font-size);
  // --mdc-protected-button-label-text-size: var(--sm-txt-font-size);
  // padding: var(--btn-big-vertical-padding) var(--mat-protected-button-horizontal-padding);
  --mdc-protected-button-container-height: var(--btn-large-height);
}

.btn.large.sp, .mat-mdc-raised-button.btn.large.sp, .btn.mdc-button--raised.large.sp {
  padding: 4px;
}

.btn.large.small-text, .mat-mdc-raised-button.btn.large.small-text, .btn.mdc-button--raised.large.small-text {
  --mdc-protected-button-label-text-size: var(--sm-txt-font-size);
}

.btn.small, .mat-mdc-raised-button.btn.small, .btn.mdc-button--raised.small {
  padding: 4px;
  --mdc-protected-button-container-height: initial;
  --mdc-protected-button-label-text-size: var(--btn-sm-font-size);
  .mat-mdc-button-touch-target {
    height: 24px;
  }
}

.buttons {
  justify-content: center;
  padding: 3px;
  // background-color: var(--gray-70);
  border-radius: var(--radius-sm);
  .buttons-group {
    display: flex;
    padding: var(--spacing-xs);
    border: solid 1px var(--gray-60);
    border-radius: var(--radius-sm);
  }
  .button-wrap {
    // flex: 1;
    button {
      // width: 100%;
      img {
        width: 16px;
        height: 36px;
        object-fit: scale-down;
        vertical-align: middle;
      }
      .mdc-button__label {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs);
      }
    }
  }
  .button-wrap:not(:first-child) {
    margin-left: var(--spacing-xs);
  }
}

.buttons.ghost {
  background-color: transparent;
  border: solid 1px var(--gray-10);
  padding: 0;
  .button-wrap:not(:first-child) {
    border-left: solid 1px var(--gray-10);
  }
}

.buttons.btn-imgs {
  .button-wrap:not(:first-child) {
    margin-left: 0;
  }
}

.btn-group.mat-button-toggle-group {
  --mat-standard-button-toggle-divider-color: var(--gray-70);
  --mat-standard-button-toggle-shape: var(--border-radius);
  --mat-standard-button-toggle-selected-state-background-color: var(--gradient-2);
  // --mat-standard-button-toggle-selected-state-text-color: 
  padding: 3px;
  background-color: var(--gray-70);
  mat-button-toggle.mat-button-toggle-appearance-standard {
    border-color: var(--gray-10);
  }
  .mat-button-toggle-button {
    color: var(--gray-10);
    background: var(--gray-70);
    transition-duration: var(--duration);
    min-width: 48px;
  }
  .mat-button-toggle-checked {
    .mat-button-toggle-button {
      background: var(--gradient-2);
      img {
        filter: invert(1);
      }
      img.invert {
        filter: invert(0);
      }
    }
  }
}

.btn-group.mat-button-toggle-group.btn-group-a {
  --mat-standard-button-toggle-divider-color: transparent;
  --mat-standard-button-toggle-selected-state-background-color: var(--gray-70);
  mat-button-toggle.mat-button-toggle-appearance-standard {
    border-color: transparent;
    --mat-standard-button-toggle-divider-color: transparent;
  }
  .mat-button-toggle-checked {
    .mat-button-toggle-button {
      background: var(--gray-10);
      color: var(--bg-a);
    }
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-shape: var(--radius-md);
  border-radius: var(--mdc-snackbar-container-shape);
  --mdc-snackbar-supporting-text-weight: var(--font-weight-semi-bold);
  simple-snack-bar {
    position: relative;
    border-radius: var(--mdc-snackbar-container-shape);
    padding: var(--spacing-sm) var(--spacing-md) var(--spacing-sm) var(--spacing-xl);
    // font-weight: var(--font-weight-semi-bold);
    .mat-mdc-snack-bar-action.mat-mdc-button {
      font-weight: var(--font-weight-bold);
    }
    .mat-mdc-snack-bar-label.mdc-snackbar__label::first-letter {
      text-transform: capitalize;
    }
  }
  simple-snack-bar::before {
    position: absolute;
    content: ' ';
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    left: var(--spacing-md);
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.success {
  background: var(--green-10);
  --mdc-snackbar-container-color: var(--green-10);
  .mat-mdc-snackbar-surface {
    --mdc-snackbar-supporting-text-color: var(--bg-a);
  }
  simple-snack-bar {
    background: var(--green-10);
    --mdc-snackbar-supporting-text-color: var(--bg-a);
    border-left: solid 6px var(--green-40);
    .mat-mdc-snack-bar-action.mat-mdc-button:not(:disabled) {
        color: var(--green-30);
    }
  }
  simple-snack-bar::before {
    background-image: url('/assets/images/green-v.svg');
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.error {
  background: var(--red-10);
  --mdc-snackbar-container-color: var(--red-10);
  .mat-mdc-snackbar-surface {
    --mdc-snackbar-supporting-text-color: var(--bg-a);
  }
  simple-snack-bar {
    background: var(--red-10);
    --mdc-snackbar-supporting-text-color: var(--bg-a);
    border-left: solid 6px var(--red-40);
    .mat-mdc-snack-bar-action.mat-mdc-button:not(:disabled) {
      color: var(--red-40);
    }
  }
  simple-snack-bar::before {
    background-image: url('/assets/images/error.svg');
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.info {
  background: var(--gray-70);
  --mdc-snackbar-container-color: var(--gray-70);
  .mat-mdc-snackbar-surface {
    --mdc-snackbar-supporting-text-color: var(--gray-10);
  }
  simple-snack-bar {
    background: var(--gray-70);
    --mdc-snackbar-supporting-text-color: var(--gray-10);
    border-left: solid 6px var(--blue-20);
    .mat-mdc-snack-bar-action.mat-mdc-button:not(:disabled) {
      color: var(--gray-10);
    }
  }
  simple-snack-bar::before {
    background-image: url('/assets/images/info.svg');
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.attention {
  background: var(--gray-70);
  --mdc-snackbar-container-color: var(--gray-70);
  .mat-mdc-snackbar-surface {
    --mdc-snackbar-supporting-text-color: var(--gray-10);
  }
  simple-snack-bar {
    background: var(--gray-70);
    --mdc-snackbar-supporting-text-color: var(--gray-10);
    border-left: solid 6px var(--orange-20);
    .mat-mdc-snack-bar-action.mat-mdc-button:not(:disabled) {
      color: var(--gray-10);
    }
  }
  simple-snack-bar::before {
    background-image: url('/assets/images/attention.svg');
  }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.neutral {
  background: var(--gray-70);
  --mdc-snackbar-container-color: var(--gray-70);
  .mat-mdc-snackbar-surface {
    --mdc-snackbar-supporting-text-color: var(--gray-10);
  }
  simple-snack-bar {
    background: var(--gray-70);
    --mdc-snackbar-supporting-text-color: var(--gray-10);
    border-left: solid 6px var(--theme-color-2);
    .mat-mdc-snack-bar-action.mat-mdc-button:not(:disabled) {
      color: var(--gray-10);
    }
  }
  simple-snack-bar::before {
    background-image: url('/assets/images/material.svg');
  }
}

mat-form-field {
  --mdc-filled-text-field-hover-active-indicator-color: var(--gray-30);
  --mat-form-field-container-height: 40px;
}
// mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover {
//   --mdc-filled-text-field-hover-active-indicator-color: --gray-20;
//   .mdc-line-ripple::before {

//   }
// }

mat-form-field .mdc-text-field--filled {
  --mdc-filled-text-field-container-shape: var(--radius-sm);
  border-bottom-right-radius: var(--radius-sm);
  border-bottom-left-radius: var(--radius-sm);
  --mdc-filled-text-field-hover-active-indicator-color: transparent;
  border: solid 1px transparent;
  transition-duration: var(--duration);
  transition-property: border;
  --mdc-filled-text-field-active-indicator-color: transparent;
  --mdc-filled-text-field-focus-active-indicator-color: transparent;
}

mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled):hover {
  border: solid 1px var(--gray-60);
}

mat-form-field:has(:focus) {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border: solid 1px var(--gray-60);
  }
}

mat-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--gray-70);
  --mdc-filled-text-field-label-text-color: var(--text-color);
  --mdc-filled-text-field-input-text-color: var(--text-color);
  --mdc-filled-text-field-hover-label-text-color: var(--text-color);
}

mat-form-field.t-bg .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: var(--input-transparent-bg-color);
  --mdc-filled-text-field-active-indicator-color: var(--input-transparent-bg-border);
  // .mdc-line-ripple::before {

  // }
}

mat-form-field .mdc-text-field--filled.mdc-text-field--disabled {
  --mdc-filled-text-field-disabled-container-color: var(--gray-60);
  --mdc-filled-text-field-disabled-input-text-color: var(--gray-50);
}
// .mdc-text-field--filled:not(.mdc-text-field--disabled):has(textarea) {
//   background: var(--gray-70);
// }
// textarea.mdc-text-field__input {
//   background: var(--gray-70);
// }

app-root {
  height: 100%;
  display: block;
}

hexa-viewer {
  height: 100%;
  display: block;
}

app-site {
  overflow-x: hidden;
}

// general classes for this specific platform

app-progress-bar {
  display: block;
  height: var(--progress-bar-height);
}

.ttl-hero {
  color: var(--gray-10);
  font-size: var(--h1-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h1-line-height);
  margin: 0;
}

.ttl-xl {
  color: var(--gray-10);
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h2-line-height);
  margin: 0;
}

.ttl-lg {
  color: var(--gray-10);
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h3-line-height);
  margin: 0;
}

.ttl-md {
  color: var(--gray-10);
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h4-line-height);
  margin: 0;
}

.ttl-sm {
  color: var(--gray-10);
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h5-line-height);
  margin: 0;
}

.ttl-xs {
  color: var(--gray-10);
  font-size: var(--h6-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h6-line-height);
  margin: 0;
}

.ttl-xxs {
  color: var(--gray-10);
  font-size: var(--h7-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--h7-line-height);
  margin: 0;
}

.txt-lg {
  color: var(--gray-10);
  font-size: var(--xl-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--xl-line-height);
}

.txt-md {
  color: var(--gray-10);
  font-size: var(--md-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--md-line-height);
}

.txt-md.bold {
  font-weight: var(--font-weight-bold);
}

.txt-sm {
  color: var(--gray-10);
  font-size: var(--sm-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--sm-line-height);
}

.txt-sm.bold {
  font-weight: var(--font-weight-bold);
}

.txt-xs {
  color: var(--gray-10);
  font-size: var(--xs-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--xs-line-height);
}

.txt-xs.bold {
  font-weight: var(--font-weight-bold);
}

.txt-xxs {
  color: var(--gray-10);
  font-size: var(--xxs-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--xxs-line-height);
}

.txt-xxs.bold {
  font-weight: var(--font-weight-bold);
}

.txt-xxxs {
  color: var(--gray-10);
  font-size: var(--xxxs-txt-font-size);
  font-weight: var(--font-weight-nornal);
  line-height: var(--xxxs-line-height);
}

.txt-xxxs.bold {
  font-weight: var(--font-weight-bold);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.gradient-dark {
  background: var(--gradient-dark);
}

.err-color, .txt-md.err-color {
  color: var(--red-30);
}

.warn-color {
  color: var(--orange-20);
}

.error-color,
.btn-d.error-color, .mat-mdc-raised-button.btn-d.error-color,
.mat-mdc-raised-button:not(:disabled).btn-d.error-color, .btn-d.mdc-button--raised.error-color {
  color: var(--red-30);
}

.gray-50-color {
  color: var(--gray-50);
}


.disable-color {
  color: var(--gray-50);
}

.default-color {
  color: var(--text-color);
}

.success-color {
  color: var(--green-30);
}

.component-wrap {
  padding: var(--padd-vertical) var(--padd-horizontal);
}

.link {
  color: var(--link-text-color);
}

.underline {
  text-decoration: underline;
}

.td-none {
  text-decoration: none;
}

.line-through {
  text-decoration: line-through;
}

.text-decoration-none {
  text-decoration: none;
}

.link::first-letter {
  text-transform: capitalize;
}

mat-form-field:has(mat-label), mat-form-field.hide-placeholder {
  ::placeholder {
    color: transparent !important;
  }
}

::placeholder {
  color: var(--gray-50) !important;
}

.hide-placeholder::placeholder {
  color: transparent !important;
}

body {
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    --mat-form-field-container-vertical-padding: 10px;
  }
  mat-form-field:not(.has-padd) {
    .mdc-text-field {
      padding: 0;
    }
  }
  .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
  mat-form-field.no-subscript {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      border-radius: var(--radius-sm);
    }
    .mat-mdc-dialog-content {
      font-family: var(--font-family);
    }
    [mat-dialog-title] {
      font-weight: var(--font-weight-bold);
    }
  }
  .mdc-text-field__input {
    // text-indent: var(--spacing-md);
    text-indent: 0;
    padding: 0 var(--spacing-md);
  }
  mat-form-field {
    font-size: var(--sm-txt-font-size);
    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-size: var(--sm-txt-font-size);
    }
  }
  // Quill
  .ql-editor.ql-blank::before, .ql-snow .ql-picker, .ql-snow .ql-stroke {
    color: var(--text-color);
    stroke: var(--text-color);
  }
  .ql-showHtml::after {
    content: "</>";
    color: var(--text-color);
    font-weight: bold;
  }
}

body.full-mat-mdc-dialog-container {
  .mat-mdc-dialog-container {
    width: calc(100vw - 40px);
  }
}

.border-gradient {
  position: relative;
  overflow: hidden;
  border-color: transparent !important;
}

.border-gradient:before {
  content: "";
  background: var(--gradient-2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 120%;
  z-index: -1;
  animation: rotate-gradient 3s linear infinite;
}

.border-gradient.bg-rel:before {
  top: 0;
  left: 0;
  transform: unset;
}

.border-gradient.ptl:before {
  top: -50%;
  left: -50%;
}

.animated-border {
  position: relative;
  overflow: hidden;
}

.animated-border:before {
  content: "";
  background: conic-gradient(transparent 270deg, currentColor, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate-gradient 3s linear infinite;
}

.animated-border-inner {
  width: calc(100% - 4px);
  position: relative;
  margin: 2px;
  background-color: var(--body-bg);
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: var(--duration-slow);
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: var(--duration-slow);
}

.appear {
  animation: appear linear;
  animation-timeline: view();
  animation-range: entry 5% cover 15%;
}

.hidden-video {
  opacity: 0.01;
  position: absolute;
  z-index: -1;
  transform: scale(0.01);
}

#g_a11y_announcement {
  inset-inline-start: -10000px;
}

@keyframes rotate-gradient {
  0% {
    transform: translate(-50%,-50%) scale(1.4) rotate(0turn);
  }
  100% {
    transform: translate(-50%,-50%) scale(1.4) rotate(1turn);
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeOut {
  from {
      opacity: 1;
      transform: scale(1);
  }
  to {
      opacity: 0;
      transform: scale(0);
  }
}

@keyframes appear {
  from {
      opacity: 0;
      transform: scale(0.8);
      // scale: 0.8;
  }
  to {
      opacity: 1;
      transform: scale(1);
      // scale: 1;
  }
}